.gallery__section {
  padding: 10rem 0;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../assets/carousel-2.jpg"), no-repeat center center;
  background-size: cover;
  margin-top: 6rem;
}

.gallery__container {
  position: relative;
  display: grid;
  place-items: center;
  row-gap: 0;
  grid-template-columns: repeat(3, 1fr);
  color: var(--white);
}

.gallery__img {
  position: relative;
  overflow: hidden;
  /* aspect-ratio: 1.5/1; */
  /* z-index: 9; */
}

.gallery__img img {
  width: 100%;
  height: auto;
  opacity: 1;
  object-fit: cover;
  transition: 0.5s ease;
}

.gallery__image-icon {
  position: absolute;
  top: calc(50% - 2rem);
  left: calc(50% - 2rem);
  color: #fff;
  font-size: 3rem;
  opacity: 0.5;
  transition: 0.5 ease;
  cursor: pointer;
}

.gallery__img:hover img {
  opacity: 0.7;
}

.gallery__img:hover .gallery__image-icon {
  opacity: 1;
}

/* ============================ Gallery Mobile ========================================== */
@media screen and (max-width: 900px) {
  .gallery__container {
    grid-template-columns: repeat(1, 1fr);
  }

  .gallery__img {
    height: auto;
  }
}

/* @media screen and (min-width: 1024px) {
  .gallery__img {
      height: 250.954px;
  }
} */

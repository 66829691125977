/***================ Desktop Screen ====================***/
.service__section {
  background: #fff;
}

.service__container {
  padding: 10rem 0;
}

.service .service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.2);
}

.text_title {
  font-size: 3rem;
}

.text_subtitle {
  font-size: 2.5rem;
}

.service_con {
  display: grid;
  place-content: center;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 2rem;
}

.service-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  /* grid-template-columns: repeat(2, 1fr); */
  background-color: #eff5f9 !important;
  margin-top: 30px;
  margin-right: 30px;
  height: 350px;
  padding: 0 30px;
  border-radius: 8px;
  box-shadow: 0 0 17px rgba(0, 0, 0, 0.2);
  /* box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1); */
  transition: all 0.5s ease;
  cursor: default;
}

.service-card:hover {
  box-shadow: 0 0 34px rgba(0, 0, 0, 0.3);
  /* box-shadow: 0 14px 42px 0 rgba(0, 0, 0, 0.2); */
}

.service_icon {
  width: 150px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  border-radius: 50%;
  transform: rotate(-14deg);
}

.service_icon i {
  font-weight: 900;
  font-size: 2em;
  color: #fff;
  transform: rotate(8deg);
}

.service_con h5 {
  font-size: 2.2rem;
}

.service_icon p {
  text-align: left;
}

.service-btn {
  margin-top: 3rem;
  text-align: center;
  margin-inline: auto;
}

/* ===================== Media Query for service section ================================== */
@media screen and (max-width: 900px) {
  .service_con {
    grid-template-columns: 1fr;
  }

  .service-card {
    margin-top: 0;
    margin-right: 0;
    margin-top: 15px;
  }

  .text_title {
    font-size: 2.5rem;
  }
}

.footer__section {
  background-color: var(--black);
}

.footer__items {
  padding: 10rem 0;
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.footer__item-1 {
  display: flex;
  flex-direction: column;
  width: 33.333333%;
  gap: 2rem;
}

.footer_logo {
  width: 250px;
}

.footer__p-item-1 {
  font-size: 17px;
  font-weight: 500;
  color: var(--dark-gray);
  /* color: var(--white); */
}

.footer__socials {
  display: flex;
  gap: 1.75rem;
  color: var(--dark-blue);
  cursor: pointer;
}

.footer__socials-icon {
  background-color: var(--gray);
  padding: 1rem;
  border-radius: 9999px;
  padding-left: 14px;
  padding-right: 14px;
}

.footer__socials-icon:hover {
  background-color: var(--primary);
  /* color: var(--dark-green); */
}

.footer__socials :nth-child(2) {
  background-color: var(--gray);
  padding: 1rem;
  border-radius: 9999px;
}

.footer__socials :nth-child(2):hover {
  background-color: var(--primary);
  /* color: var(--dark-green); */
}

.footer__socials :nth-child(4) {
  background-color: var(--gray);
  padding: 1rem;
  border-radius: 9999px;
}

.footer__socials :nth-child(4):hover {
  background-color: var(--primary);
  /* color: var(--dark-green); */
}

.policy {
  font-size: 16px;
  font-weight: 500;
  color: var(--dark-gray);
}

.footer__item-2 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.footer__header-item-2 {
  font-size: 22px;
  font-weight: 700;
  color: var(--white);
}

.footer__underline {
  position: absolute;
  top: 33px;
  width: 7rem;
  height: 4px;
  background-color: var(--primary);
}

.footer__links {
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  color: var(--dark-gray);
  transition: var(--transition);
}

.footer__links:hover {
  color: var(--primary);
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: 700;
}

.footer__item-3 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.footer__header-item-3 {
  font-size: 22px;
  font-weight: 700;
  color: var(--white);
}

.work-day {
  font-size: 17px;
  color: var(--dark-gray);
  font-weight: 700;
}

.work-time {
  font-size: 16px;
  color: var(--dark-gray);
  font-weight: 500;
}

@media screen and (max-width: 620px) {
  .footer__items {
    flex-direction: column;
    align-items: center;
    gap: 5rem;
    text-align: center;
  }

  .footer_logo {
    margin-inline: auto;
    width: 180px;
  }

  .footer__item-1 {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .footer__socials {
    justify-content: center;
  }
}

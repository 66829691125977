.slide {
  display: grid;
  place-items: center;
}

.slide img {
  object-fit: cover;
  width: 100%;
}

.hero-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* flex: 0 0 auto; */
  margin-inline: auto;
  text-align: center;
}

.hero-p {
  color: var(--primary);
  text-transform: uppercase;
  font-size: 2.5rem;
  margin: 2rem 0;
}

.hero-con h1 {
  /* width: 70.6666%; */
  font-size: 7rem;
  line-height: 1;
  font-family: var(--font-header);
  font-weight: 700;
  color: #fff;
}

.hero-con p {
  font-size: 2rem;
  /* width: 66.6666%; */
  color: #fff;
  padding: 2.5rem 0;
}

.slide-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.inner-carousel {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.btn_main {
  color: var(--dark-green);
  text-align: left;
  background-color: var(--primary);
  padding: 1.2rem 3rem;
  border-radius: var(--radius-3);
  border: transparent;
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  animation: bounce 2s ease-in-out infinite;
}

/* =================== SLIDE BUTTON ====================== */
.react-slideshow-container .default-nav {
  height: 50px;
  background: var(--primary);
  width: 50px;
  border: 0;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* MEDIA QUERY FOR HERO SECTION */
@media screen and (max-width: 900px) {
  .slide img {
    object-fit: cover;
    width: 100%;
    height: 100ch;
  }

  .hero-p {
    font-size: 2rem;
    margin: 1rem;
  }

  .hero-con {
    display: grid;
    place-items: center;
    margin: 0 3rem;
  }

  .hero-con h1 {
    /* width: 30.6666%; */
    width: 100%;
    font-size: 2.5rem;
    color: #fff;
    line-height: 1;
  }

  .hero-con p {
    /* width: 40.6666%; */
    text-align: left;
    padding: 1.5rem 0;
  }

  .btn_main {
    font-size: 1.5rem;
    margin: 0 auto;
    text-align: center;
  }

  .react-slideshow-container .default-nav {
    height: 40px;
    width: 40px;
  }
}

@media screen and (max-width: 450px) {
  .hero-con h1 {
    /* width: 26.6666%; */
    font-size: 5rem;
    color: #fff;
    text-align: center;
  }

  .hero-con p {
    /* width: 25.6666%; */
    text-align: center;
  }
}

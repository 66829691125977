.testimonial__section {
  padding: 10rem 0;
  background-color: var(--bs-light);
}

.slick-prev:before,
.slick-next:before {
  font-size: 25px;
  opacity: 0.8;
  color: var(--primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section__grid {
  /* display: grid;
    grid-template-columns: repeat(3, 1fr); */
  gap: 2rem;
  text-align: center;
}

.section__card {
  position: relative;
  isolation: isolate;
  overflow: hidden;
  padding: 5rem 2rem 2rem;
  height: 600px;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  text-align: center;
}

.section__card::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  width: 75%;
  aspect-ratio: 1;
  border-radius: 100%;
  background-color: var(--primary);
  z-index: -1;
  transition: 0.5s;
}

.section__card span {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  font-size: 3rem;
  color: var(--white);
}

.section__card h4 {
  margin-top: 6rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 600;
  color: var(--primary);
  transition: 0.3s;
}

.section__card p {
  margin-bottom: 2rem;
  color: var(--dark);
  transition: 0.3s;
  font-size: 1.5rem;
}

.section__card img {
  margin-bottom: 1rem;
  max-width: 100px;
  border-radius: 100%;
  border: 2px solid var(--primary);
  transition: 0.3s;
  margin-inline: auto;
}

.section__card h5 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--primary);
  transition: 0.3s;
}

.section__card h6 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--dark-gray);
  transition: 0.3s;
}

.section__card:hover::before {
  width: 400%;
}

.section__card:hover :is(h4, h5) {
  color: var(--white);
}

.section__card:hover :is(p, h6) {
  color: var(--white);
  font-weight: 500;
}

.section__card:hover img {
  border-color: var(--white);
}

/*==================== MOBIEL VIEW ===========================*/

@media screen and (max-width: 1000px) {
  .section__grid {
    grid-template-columns: 1fr;
  }

  .section__card:hover::before {
    width: 500%;
  }

  .section__card h4 {
    margin-top: 4rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 600;
    color: var(--primary);
    transition: 0.3s;
    height: auto;
  }
}
